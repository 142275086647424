import React from 'react';
import PropTypes from 'prop-types';
import styles from './Colorbar.module.css';
import LinearGradient from "react-native-web-linear-gradient";
import {Container} from "react-bootstrap";
import { StyleSheet, View, Text } from 'react-native';

const Colorbar = () => (
    <Container fluid  >
        <LinearGradient
            colors={['red', 'orange', 'yellow', 'lightgreen', 'lightblue', 'blue', 'purple']}
            locations={[0,0.14,0.28,0.42,0.56,0.7,0.84,1]}
            start={{x: 0.0, y: 1.0}} end={{x: 1.0, y: 1.0}}
            style={{ flex: 1, height: 6, alignItems: 'center', justifyContent: 'center'}}
        >
        </LinearGradient></Container>
);
const mystyles = StyleSheet.create({
    containerMain: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection:"colum",
        flexGrow: 1
    }
});
Colorbar.propTypes = {};

Colorbar.defaultProps = {};

export default Colorbar;
