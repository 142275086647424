    import React from 'react';
import PropTypes from 'prop-types';
    import styles from './About.module.css';
    import aboutFoto from "../../assets/images/walensee.jpg";

const About = () => (
<div>
    <div className={styles.container1}>
        <div className={styles.itemFoto}>
            <img src={aboutFoto} alt="Image here" className={styles.foto}/>
            <h3 className={styles.fotoText}> Gesamtheitliche Meridiantherapie nach Hartmann (GMT) </h3>
        </div>
    </div>
    <div className={styles.container2}>
        <div className={styles.itemText}>
            <div  className={styles.textParagr}>
            <p>
            <h6>Behandlungen </h6>
                <li>Allergien (auch Lactose-Intoleranz, Zöliakie und Sonnenallergie)</li>
                <li> Phobien, Blockaden und Ängste</li>
                <li> Energetische Behandlungen</li>
                <li> Gelenk- und Gliederschmerzen</li>
                <li> Hautprobleme, Psoriasis, Schuppenflechten</li>
                <li> Lebensmittelunverträglichkeiten</li>
                <li> Gicht</li>
                <li> Asthma, Leistungsasthma, Heuschnupfen</li>
                <li> Migräne, Schleudertrauma</li>
                <li> Rheuma</li>
                <li> Nägelkauen</li>
                <li> Usw.</li>
            Auch Kinder sprechen gut auf diese Behandlungsmethode an.</p>
            </div>
        </div>
        <div className={styles.itemText}>
            <p className={styles.textParagr}>

                <h6>Ganzheitliche Meridiantherapie nach Hartmann (GMT)</h6>
                Meridianklopftherapien sind Behandlungsverfahren der energetischen Psychologie, welche sich schon
                seit Langem vor Allem in psychotherapeutischen Praxen als Möglichkeit, Stressmuster und Traumata
                zu lösen, bewährt haben. Dieser Behandlungsansatz wurde von René Hartmann zu einer auch im
                körperlichen Bereich hocheffektiven energetischen Heilmethode weiterentwickelt, die bereits vielen
                Menschen mit unterschiedlichsten gesundheitlichen Fragestellungen nachhaltige Gesundungserfolge
                gebracht hat. Das erstaunliche an dieser Methode ist, dass sie sowohl seelische Blockaden, Ängste
                und Traumata lösen kann, wie auch sehr schnell wirksam bei Allergien eingesetzt werden kann. Es
                werden innerhalb einer Sitzung eine bestimmte Abfolge von Meridianpunkten entsprechend den
                Akupunkturpunkten mit den Fingern geklopft und durch Energieheilarbeit unterstützt. Der vermutete
                Wirkmechanismus: In den so angeregten Meridianen (piezoelektrischer Effekt) wird der
                Biophotonenfluss systemisch aktiviert, sodass über die auf dem Solarplexus gelegte Störinformation
                (Allergene, seelische Themen) eine Eigenregulation aktiviert wird. In dieser Weise lassen sich isolierte
                Ängste/Phobien aber auch Allergien wie Heuschnupfen, Hausstauballergie oder Nahrungsmittel -
                intoleranzen verhältnismässig schnell auflösen, wodurch sich Heilungsprozesse deutlich
                beschleunigen können.
            </p>
        </div>
    </div>
</div>
);

About.propTypes = {};

About.defaultProps = {};

export default About;
