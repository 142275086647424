import React from 'react';
import PropTypes from 'prop-types';
import styles from './Home.module.css';
import {View} from 'react-native';
import portraitFoto from '../../assets/images/Baumann Daniel 30.03.2023-08.jpg';


const Home = () => (
    <div className={styles.container}>
      <div className={styles.itemFoto}>
          <img src={portraitFoto} alt="Image here" className={styles.foto}/>
      </div>
      <div className={styles.itemText}>
          <h2> Daniel Baumann </h2>
          <h6> Meridiantherapeut</h6>
          <p> Ich bin ein offener und kommunikativer Mensch, der gerne mit Freunden etwas unternimmt, aber auch
              die Ruhe in der Natur geniesst. Zwölf Jahre bin ich einer Tätigkeit im Baugewerbe nachgegangen,
              danach habe ich in die Gastronomie gewechselt. Nach sieben Jahren in der Gastronomie, war es für
              mich an der Zeit für eine Veränderung. </p>
      </div>
        <div className={styles.itemText}>

            <p>
                Aufgrund meines eigenen Leidens an Heuschnupfen und Laktoseintoleranz wurde mir von einem
                Bekannten René Hartman und die ganzheitliche Meridiantherapie (GMT) empfohlen. Die GMT zeigte
                bei mir vollen Erfolg und was man alles damit erreichen kann, faszinierte mich auch 10 Jahre später
                noch sehr. Dies hat mich dazu bewogen, diese Therapiemethode selbst zu erlernen und nun auch zu
                praktizieren.
                Ich selbst habe durch diese Therapiemethode an Lebensqualität gewonnen und freue mich, dass die
                GMT auch bei meinen behandelten Personen grossen Erfolg zeigt. </p>
        </div>
    </div>



);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;

/*
 <div className={styles.Home} >
    <h1>Daniel Baumann</h1>
    <p>
      Hier kommt der Text mit Lebenslauf
    </p>
  </div>
  <div className={styles.Home}>
    <img src={portraitFoto} alt="Image here" height="100"/>
  </div>
 */

/*

<div>

  <View className={styles.container}>
    <View className={styles.box} style={{flex: 1, backgroundColor: 'red'}}>
      Daniel Baumann
      </View>
    <View className={styles.box} style={{flex: 2, backgroundColor: 'darkorange'}}>
      hier kommt text
    </View>
    <View className={styles.box} style={{flex: 3, backgroundColor: 'green'}}>
      <img src={portraitFoto} alt="Image here" height="100"/>
    </View>

  </View>
</div>

 */
