import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contact.module.css';
import aboutFoto from "../../assets/images/Foto Quelle Thal.JPG";
import aboutFoto2 from "../../assets/images/20221115_161310.jpg";

const Contact = () => (
<div className={styles.Contact}>
  <div className={styles.itemText}>
      <h3>Adresse</h3>
      <p>Herisauerstrasse 63 <br />
          9015 St. Gallen</p>
  </div>
  <div className={styles.itemMap}>
      <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2700.408028198778!2d9.300067611686705!3d47.403981971051756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479ae0684e3c5811%3A0xfa305e785fe61429!2sHerisauer%20Str.%2063%2C%209015%20St.%20Gallen!5e0!3m2!1sen!2sch!4v1682620576717!5m2!1sen!2sch"
          frameborder="0" allowFullScreen="" loading="lazy" height="100%" width="100%"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
  </div>
  <div className={styles.itemText}>
      <h3>Telefon</h3>
      <p>Mobil 079 412 49 29       <br/>
      </p>
  </div>
</div>
);

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
/*

  <div className={styles.itemFoto}>
      <img src={aboutFoto} alt="Image here" height="100%" width="100%"/>
  </div>
  <div className={styles.itemText}>
      <h3>Mail</h3>
      <p>daniel@baumann.ch</p>
  </div>
    <div className={styles.itemFoto}>
        <img src={aboutFoto2} alt="Image here" height="100%" width="100%"/>
    </div>

 */
