import 'bootstrap/dist/css/bootstrap.min.css';
// import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
//import {HashRouter, Route, Routes} from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Home from './components/Home/Home';
import Praxis from "./components/Praxis/Praxis";
import Navigationbar from './components/Navigationbar/Navigationbar';
import Colorbar from "./components/Colorbar/Colorbar";
import simpleLogo from "./assets/images/Logo.png"
import {Container} from "react-bootstrap";
import LinearGradient from "react-native-web-linear-gradient";
import React from "react";


import { Route, HashRouter as Router, Routes } from "react-router-dom";


function App() {
    return (

        <div className="App">

            <Navigationbar />

            <Router>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/contact" element={<Contact />} />
                </Routes>
            </Router>


            <Colorbar />
        </div>
    );
}

  /*
            <HashRouter>

                    <Route exact path='/' component={<Home/>} />
                    <Route exact path='/about' component={<About/>} />
                    <Route exact path='/praxis' component={<Praxis/>} />
                    <Route exact path='/contact' component={<Contact/>} />

            </HashRouter>



    return (



     <div className="App">

            <Navigationbar />

            <HashRouter>
                <Routes>


                    <Route path='/' component={ Home }  exact  />
                    <Route path='/about' component={ About } exact   />
                    <Route path='/praxis' component={ Praxis } exact   />
                    <Route path='/contact' component={ Contact }  exact  />
                    </Routes>
            </HashRouter>
            <Colorbar />
        </div>
  );
}

   */

export default App;
